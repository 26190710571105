<template>
  <v-card>
    <v-container>
      <v-tabs background-color="transparent" v-model="tabs">
        <v-tab href="#publicView">Vistas públicas</v-tab>
        <v-tab href="#privateView">Vistas privadas</v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item value="publicView">
            <div id="view-spinner" v-show="loadingViews">
              <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                :indeterminate="loadingViews"
              ></v-progress-circular>
            </div>
            <div id="view-container">
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="item in publicViews" :key="item.viewId">
                    <v-list-item-content>
                      <v-list-item-title @click="selectView(item)">
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        v-if="item.viewId === viewId"
                        small
                        color="primary"
                      >
                        {{ checkIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-icon size="20" @click="deleteVista(item.viewId)">
                        {{ deleteIcon }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>
          <v-tab-item value="privateView">
            <div id="view-container">
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="item in privateViews" :key="item.viewId">
                    <v-list-item-content>
                      <v-list-item-title @click="selectView(item)"
                        >{{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon
                        v-if="item.viewId === viewId"
                        small
                        color="primary"
                      >
                        {{ checkIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-icon size="20" @click="deleteVista(item.viewId)">
                        {{ deleteIcon }}
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        <v-btn
          color="primary"
          @click="guardarVista()"
          type="submit"
          v-if="!noFiltersInsert"
          :loading="loadGuardar"
          >Guardar</v-btn
        >
        <v-btn
          color="primary"
          @click="openModalGuardarComo()"
          v-if="!noFiltersInsert"
          >Guardar como</v-btn
        >
      </v-card-actions>
      <v-dialog
        v-if="this.modalSelectView === true"
        v-model="modalSelectView"
        max-width="400px"
      >
        <v-card>
          <v-container>
            <v-card-text>
              <v-card-title class="headline primary--text">
                ¿Desea seleccionar la vista "{{ view.name }}"?
              </v-card-title>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeModalSelectView()"> Cancelar </v-btn>
              <v-btn text color="primary" dark @click="vistaSeleccionada()">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card></v-dialog
      >
      <v-dialog
        v-if="this.modalGuardarComo === true"
        v-model="modalGuardarComo"
        max-width="550px"
      >
        <v-card>
          <v-container>
            <v-card-text>
              <v-subheader v-if="this.esPrivado === false">
                *Esta vista estará disponible para todos los usuarios*
              </v-subheader>
              <v-subheader v-if="this.esPrivado === true">
                *Esta vista estará disponible sólo para usted*
              </v-subheader>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    v-model.trim="viewName"
                    label="Nombre de la vista"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.maxLength(viewName, 60),
                        rules.requiredTrim(viewName)
                      ])
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-switch
                    v-model="esPrivado"
                    label="Vista privada"
                    item-text="isPrivate"
                    item-value="id"
                    dense
                    outlined
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-select
                    outlined
                    clearable
                    dense
                    :disabled="esPrivado"
                    multiple
                    v-model="usuariosSelected"
                    label="Usuarios con permiso de edición"
                    item-text="value"
                    item-value="id"
                    autocomplete="on"
                    :items="usuarios"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="usuariosToggle()">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              usuariosSelected.length > 0 ? 'primary' : ''
                            "
                          >
                            {{ multiselectIcon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Todos </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.value }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ usuariosSelected.length - 1 }} otros)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <div
                    v-if="usuariosSelected.length > 0"
                    id="simple-table-container"
                  >
                    <v-simple-table dense v-if="usuariosSelected.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Usuarios con permiso:</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in usuariosSelected" :key="item.id">
                            <td>{{ item.value }}</td>
                            <v-icon
                              small
                              class="ml-2 pt-2"
                              @click="deleteUsuariosSelected(item)"
                            >
                              {{ deleteIcon }}
                            </v-icon>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModalGuardarComo()">
                Cancelar
              </v-btn>
              <v-btn
                type="submit"
                form="form"
                color="primary"
                @click="saveView()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";

export default {
  props: {
    filtrosAplicados: {
      type: Object,
      required: false
    },
    viewId: {
      type: Number,
      required: false
    },
    privateView: {
      type: Boolean,
      required: false
    },
    usersView: {
      type: Array,
      required: false
    },
    viewTitle: {
      type: String,
      required: false
    }
  },
  name: "AdministrarVistas",
  components: { DeleteDialog },
  data: () => ({
    rules: rules,
    noFiltersInsert: true,
    modalSelectView: false,
    tabs: null,
    loadGuardar: false,
    esPrivado: true,
    idToDelete: null,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showDeleteModal: false,
    titleDelete: "¿Eliminar vista?",
    modalGuardarComo: false,
    viewName: "",
    deleteIcon: enums.icons.DELETE,
    usuariosSelected: [],
    viewQuery: enums.webSiteOptions.LOTES_DEUDA,
    publicViews: [],
    privateViews: [],
    view: {},
    loadingViews: true,
    vistas: [],
    usuarios: [],
    validateNameView: null
  }),
  computed: {
    multiselectIcon() {
      if (this.selectAllUsuarios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllUsuarios() {
      return (
        this.usuariosSelected &&
        this.usuariosSelected.length === this.usuarios.length
      );
    }
  },
  watch: {
    filtrosAplicados(val) {
      if (val.filters != this.view.filters) this.view.filters = val.filters;
    },
    esPrivado() {
      if (this.esPrivado === true) {
        this.usuariosSelected = [];
      }
    }
  },
  mounted() {
    this.setView();
  },
  created() {
    this.loadViews();
    this.cargarUsuarios();
    if (this.filtrosAplicados.entFacId !== null) {
      this.noFiltersInsert = false;
    }
  },
  methods: {
    ...mapActions({
      postSaveView: "devengamientos/postSaveView",
      deleteViewById: "devengamientos/deleteViewById",
      getUsuarios: "devengamientos/getUsuarios",
      getViews: "devengamientos/getViews",
      setAlert: "user/setAlert"
    }),
    setView() {
      this.view.filters = this.filtrosAplicados;
      this.view.name = this.viewTitle;
      this.view.private = this.privateView;
      this.view.id = this.viewId;
      this.view.usuarios = this.usersView;
    },
    async loadViews() {
      const response = await this.getViews(this.viewQuery);
      this.vistas = response;
      this.publicViews = this.vistas.filter(x => x.isPrivate === false);
      this.privateViews = this.vistas.filter(x => x.isPrivate === true);
      this.loadingViews = false;
    },
    closeModal() {
      this.$emit("closeModalVistas");
    },
    async cargarUsuarios() {
      const res = await this.getUsuarios();
      this.usuarios = res;
    },
    closeModalSelectView() {
      this.modalSelectView = false;
    },
    openModalGuardarComo() {
      this.modalGuardarComo = true;
    },
    closeModalGuardarComo() {
      this.viewName = "";
      this.esPrivado = true;
      this.validateNameView = null;
      this.modalGuardarComo = false;
    },
    deleteVista(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteViewById({
        viewId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadViews();
      }
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) {
          this.usuariosSelected = [];
        } else {
          this.usuariosSelected = this.usuarios;
        }
      });
    },
    async saveView() {
      if (this.esPrivado === true) {
        const dataPrivate = {
          name: this.viewName,
          optionCode: this.viewQuery,
          isPrivate: this.esPrivado,
          filters: this.filtrosAplicados,
          fechaAlta: null,
          usuarioAlta: null
        };
        const validar = this.privateViews.some(
          x =>
            x.name.toLowerCase().trim() ===
            dataPrivate.name.toLowerCase().trim()
        );
        this.validateNameView = validar;

        if (this.validateNameView === true) {
          this.setAlert({
            type: "warning",
            message: "Ya existe una vista con el nombre ingresado."
          });
        } else {
          const response = await this.postSaveView(dataPrivate);
          if (response.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeModal();
          }
        }
      }
      if (this.esPrivado === false) {
        const dataPublic = {
          name: this.viewName,
          optionCode: this.viewQuery,
          isPrivate: this.esPrivado,
          filters: this.filtrosAplicados,
          fechaAlta: null,
          usuarioAlta: null,
          viewUsers: this.usuariosSelected
        };
        const validar2 = this.publicViews.some(
          x =>
            x.name.toLowerCase().trim() === dataPublic.name.toLowerCase().trim()
        );
        this.validateNameView = validar2;

        if (this.validateNameView === true) {
          this.setAlert({
            type: "warning",
            message: "Ya existe una vista con el nombre ingresado."
          });
        } else {
          const res = await this.postSaveView(dataPublic);
          if (res.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeModal();
          }
        }
      }
    },
    async guardarVista() {
      if (this.viewId === null) {
        this.openModalGuardarComo();
      } else {
        this.loadGuardar = true;
        const data = {
          viewId: this.view.id,
          name: this.view.name,
          optionCode: this.viewQuery,
          isPrivate: this.view.private,
          filters: this.filtrosAplicados,
          fechaAlta: null,
          usuarioAlta: null,
          viewUsers: this.view.usuarios
        };
        const response = await this.postSaveView(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.loadGuardar = false;
          this.closeModal();
        }
      }
    },
    selectView(item) {
      this.modalSelectView = true;
      this.view = {
        id: item.viewId,
        name: item.name,
        filters: item.filters,
        private: item.isPrivate,
        usuarios: item.viewUsers
      };
    },
    vistaSeleccionada() {
      this.$emit("update:viewTitle", this.view.name);
      this.$emit("update:viewId", this.view.id);
      this.$emit("update:privateView", this.view.private);
      this.$emit("update:usersView", this.view.usuarios);
      this.$emit("update:filtrosAplicados", this.view.filters);
      this.closeModal();
    },
    deleteUsuariosSelected(item) {
      this.usuariosSelected = this.usuariosSelected.filter(
        x => x.id !== item.id
      );
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
#view-container {
  position: relative;
  height: 150px;
  overflow: auto;
}
#view-spinner {
  position: absolute;
  top: 50%;
  left: 40%;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
  z-index: 1;
}
</style>
